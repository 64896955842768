import { Injectable } from '@angular/core';
import { IProcessStage } from '@th-common-retailer/core/interfaces/process-stage.interface';
import { Observable } from 'rxjs';

import { TTileId, TTileType } from '@core/enums/claim/tile-types.enum';
import { Feature } from '@core/enums/feature.enum';
import { ClaimDetails, IClaimDetailsModel } from '@core/interfaces/claims/claimDetails.interface';
import { ClaimSearchRequest } from '@core/models/search/search-request.model';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

export interface IServiceActionFilterCheckbox {
  title: string;
  searchRequest: Partial<ClaimSearchRequest>;
  checked: boolean;
  features: Feature[];
}

@Injectable()
export abstract class ClaimsPageService {
  abstract showPortalTypeTabs: boolean;
  abstract processStages: IProcessStage[];
  abstract tileFilter: any;
  abstract claimWarrantyColumns: GridColumnModel[];
  abstract claimProductsColumns: GridColumnModel[];
  showPackageInsert(): boolean {
    return false;
  }
  showShippingLabelBtn(): boolean {
    return true;
  }

  abstract showActionCounts(): boolean;
  abstract openClaimDetailsDialog(claimId: number, singleView: boolean): void;
  abstract getDefaultColumns(showFlag: boolean): GridColumnModel[];
  abstract getAdvancedSearchActionFilters(): Observable<IServiceActionFilterCheckbox[]>;
  abstract getTileSearchRequest(tileType: TTileType, tileId: TTileId | string): Partial<ClaimSearchRequest>;
  abstract showCompleteRepairBtn(claim: ClaimDetails): boolean;
  abstract completeJewelryClaim(claim: IClaimDetailsModel): Observable<boolean>;
}
