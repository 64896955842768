import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PlanDetailsDialogService } from '@th-common-retailer/shared/dialogs/plan-details/plan-details-dialog.service';
import { of } from 'rxjs';
import { combineLatestWith, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { RetailerPlanApiService } from '@core/api/retailer-plan-api.service';
import { AnchorNavigationClass } from '@core/classes/anchor-navigation';
import { PRODUCT_FILES_DROP_AREA, RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { APP_CONFIG } from '@core/constants/app-config.constants';
import { TDocumentType } from '@core/enums/document-type';
import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { TPortalType } from '@core/enums/portal-type.enum';
import { PlanItemFacade } from '@core/facades/plan-item/plan-item.facade';
import { Files, IDropArea } from '@core/interfaces/claims/files.interface';
import { Plan, PlanDetails } from '@core/interfaces/plan/plan.interface';
import { PromptDialogService } from '@core/services/dialog/prompt-dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { PlanService } from '@core/services/plan.service';
import { PlansStore } from '@core/store/plans/plans.store';
import { PlanUtil } from '@core/utils/plan.util';
import {
  SideDialogContainerComponent,
} from '@shared/components/dialogs/side-dialog-container/side-dialog-container.component';
import { SideDialogContentComponent } from '@shared/components/dialogs/side-dialog-content/side-dialog-content.component';
import { SideDialogNavComponent } from '@shared/components/dialogs/side-dialog-nav/side-dialog-nav.component';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { BasePlanDetailsModule } from '@shared/modules/base-plan-details/base-plan-details.module';
import {
  CollapsibleDataBlockComponent,
} from '@shared/modules/collapsible/components/collapsible-data-block/collapsible-data-block.component';
import { FilesCarouselComponent } from '@shared/modules/files/components/files-carousel/files-carousel.component';
import { FilesForPrintComponent } from '@shared/modules/files/components/files-for-print/files-for-print.component';
import { FilesPreviewComponent } from '@shared/modules/files/components/files-preview/files-preview.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { AnchorNavComponent } from '@shared/modules/navigation/components/anchor-nav/anchor-nav.component';
import { NavigationModule } from '@shared/modules/navigation/navigation.module';
import { NotificationType } from '@shared/modules/notification/enums/notification-type.enum';
import { NotificationService } from '@shared/modules/notification/services/notification.service';
import {
  ItemDetailsPaginationComponent,
} from '@shared/modules/side-dialog/components/item-details-pagination/item-details-pagination.component';
import {
  SideDialogHeaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-header/side-dialog-header.component';
import {
  SideDialogLoaderComponent,
} from '@shared/modules/side-dialog/components/side-dialog-loader/side-dialog-loader.component';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';
import { WizardFormSectionComponent } from '@shared/wizard/wizard-form-section/wizard-form-section.component';
import {FeatureService} from "@core/services/feature.service";

interface IPlanDetailsState {
  currentPlanIndex: number;
  plan: PlanDetails;
  files: Files[];
  filesLoading: boolean;
  singleView: boolean;
  pdfLink: string;
  isAllowedCancelPlan: boolean;
  dropAreas: IDropArea[];
  planProcessing: boolean;
  loading: boolean;
  dialogTitle: string;
}

@Component({
  selector: 'app-plan-details',
  standalone: true,
  templateUrl: './plan-details.component.html',
  styleUrls: ['./plan-details.component.scss'],
  providers: [RxState, SideDialogStateService],
  imports: [
    NgIf,
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    NavigationModule,
    CustomOverlayScrollbarDirective,
    BasePlanDetailsModule,
    CollapsibleDataBlockComponent,
    MatLegacyProgressSpinnerModule,
    LoadingButtonDirective,
    AnchorNavComponent,
    SideDialogAnimationComponent,
    LetDirective,
    SideDialogHeaderComponent,
    ItemDetailsPaginationComponent,
    SideDialogLoaderComponent,
    FilesPreviewComponent,
    FilesForPrintComponent,
    FormFilesDropAreaComponent,
    NgForOf,
    FilesCarouselComponent,
    ThFormFieldComponent,
    ThFormFieldBlockComponent,
    WizardFormSectionComponent,
    SideDialogContainerComponent,
    SideDialogNavComponent,
    SideDialogContentComponent,
    LoaderModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDetailsComponent implements OnInit, OnDestroy {
  vm$ = this.state.select();
  plansPagination$ = this.plansStore.select$('pagination').pipe(
    switchMap(pagination =>
      this.plansStore.select$('searchRequest', 'pageSize').pipe(
        map(pageSize => ({
          ...pagination,
          pageSize,
        })),
      ),
    ),
  );
  currentPlanIndex$ = this.state.select('currentPlanIndex');
  isAllowedCancelPlan$ = this.state.select('isAllowedCancelPlan');
  loading$ = this.planItemFacade.loading$;
  filesLoading$ = this.planItemFacade.filesLoading$;
  files$ = this.planItemFacade.files$;
  singleView$ = this.state.select('singleView');
  filesLoading = true;
  planStatuses = MyProtectionPlanStatus;

  anchorNavigation = new AnchorNavigationClass({
    currentAnchor: 'Consumer',
    list: [
      { name: 'Consumer', anchor: 'Consumer' },
      { name: 'Protection Plan', anchor: 'PlanInformation' },
      { name: 'Covered Products', anchor: 'CoveredProducts' },
      { name: 'Plan Documents', anchor: 'PlanDocuments' },
    ],
  });

  planColumns = this.planDetailsDialogService.planColumns;
  coveredProductColumns: GridColumnModel[] = [];

  planUtil = inject(PlanUtil);
  promptDialogService = inject(PromptDialogService);

  dialogData = inject(MAT_DIALOG_DATA);
  appConfig = inject(APP_CONFIG);

  filesForm = this.fb.array<Files>([], [Validators.required]);

  constructor(
    private readonly sideDialogStateService: SideDialogStateService,
    public planItemFacade: PlanItemFacade,
    public planService: PlanService,
    private retailerPlanApiService: RetailerPlanApiService,
    private notification: NotificationService,
    private readonly plansStore: PlansStore,
    private readonly state: RxState<IPlanDetailsState>,
    private readonly planDetailsDialogService: PlanDetailsDialogService,
    private readonly fb: FormBuilder,
  ) {
    this.state.set({
      loading: true,
      singleView: this.dialogData.data.singleView,
    });
    this.state.connect('plan', this.planItemFacade.item$.pipe(
      filter(plan => !!plan),
      tap(plan => {
        this.coveredProductColumns = this.planDetailsDialogService.getCoveredProductColumns(plan);
      }),
    ));
    this.state.connect('loading', this.planItemFacade.loading$);
    this.state.connect('dialogTitle', this.state.select('plan').pipe(
      map(plan => plan.planInfo.consumerPlanName
        ? `Plan Details for ${plan.planInfo.name || plan.planInfo.consumerPlanName}`
        : 'Processing...',
      ),
    ));
    this.state.connect('files', this.planItemFacade.files$);
    this.state.connect('filesLoading', this.planItemFacade.filesLoading$);
    this.state.connect('dropAreas', this.state.select('files').pipe(
      combineLatestWith(this.state.select('filesLoading')),
      map(([files, filesLoading]) => {
        if (filesLoading) {
          return [];
        }

        const receiptFiles = files.filter(file => file.docType === TDocumentType.Receipt);
        const productFiles = files.filter(
          file => [TDocumentType.RetailerPortalUploadedPhoto, TDocumentType.CustPhoto].includes(file.docType),
        );
        const dropAreas: IDropArea[] = [];
        if (this.appConfig.portalType === TPortalType.Retailer || receiptFiles.length === 0) {
          dropAreas.push({
            ...RECEIPT_FILES_DROP_AREA,
            sectionSize: this.appConfig.portalType === TPortalType.Retailer
              ? TCommonFieldSize.Full : productFiles.length === 0
                ? TCommonFieldSize.Medium : TCommonFieldSize.Full,
          });
        }

        if (productFiles.length === 0 && this.appConfig.portalType === TPortalType.MJC) {
          dropAreas.push({
            ...PRODUCT_FILES_DROP_AREA,
            sectionSize: dropAreas.length === 0 ? TCommonFieldSize.Full : TCommonFieldSize.Large,
          });
        }

        return dropAreas;
      }),
    ));
    this.state.connect(
      'currentPlanIndex',
      this.state.select('plan').pipe(
        filter(planDetails => !!planDetails),
        switchMap(planDetails =>
          this.plansStore.select$('plans').pipe(
            map(plans => plans.findIndex(plan => plan.id === planDetails.planInfo.id)),
          ),
        ),
      ),
    );
    this.state.connect(
      'isAllowedCancelPlan',
      this.state.select('plan').pipe(
        filter(planDetails => !!planDetails),
        combineLatestWith(this.state.select('loading')),
        switchMap(([planDetails, loading]) =>
          loading || !planDetails.planInfo.consumerPlanName
            ? of(false)
            : this.planDetailsDialogService.showCancelPlanButton(planDetails),
        ),
      ),
    );
    this.state.connect('pdfLink', this.state.select('plan').pipe(
      switchMap(plan => {
        if (this.appConfig.portalType === TPortalType.Retailer) {
          return of(this.planUtil.getPlanPdfLink(plan.planInfo.id, 'retailerplan'));
        } else {
          return this.files$.pipe(
            map(files => {
              const planFile = files.find(file => file.docType === TDocumentType.Plan);
              return planFile ? planFile.url : '';
            }),
          );
        }
      }),
    ));
    this.state.connect('planProcessing', this.state.select('plan').pipe(
      filter(plan => !!plan),
      map(plan => !plan.planInfo.consumerPlanName),
    ));
  }

  ngOnInit(): void {
    this.planItemFacade.setCurrentId(this.dialogData.data.planId).getItem();
  }

  ngOnDestroy(): void {
    this.planItemFacade.destroy();
  }

  onClose(): void {
    this.sideDialogStateService.close();
  }

  cancelPlan(planDetails: PlanDetails): void {
    if (
      planDetails.planInfo.protectionPlanStatus !== MyProtectionPlanStatus.Registered
      && !this.state.get('isAllowedCancelPlan')
    ) {
      return;
    }
    const plan = planDetails.planInfo;
    this._openRetailerPlanCancellationModal(plan);
  }

  setPage({ page, itemIndex }: {page: number; itemIndex: number}): void {
    this.planItemFacade.changePlanDetailsPage(page, itemIndex);
  }

  changeItemIndex(itemIndex: number): void {
    const plans = this.plansStore.get('plans');
    if (plans[itemIndex]) {
      this.state.set({
        dropAreas: [],
      });
      this.filesForm.clear();
      this.planItemFacade.setCurrentId(plans[itemIndex].id).getItem();
    }
  }

  allFilesLoaded(): void {
    this.planItemFacade.allFilesLoaded();
  }

  removeFileIndex(filesFormArray: AbstractControl, fileIndex: number): void {
    const updatedFiles = filesFormArray.value.filter((file: Files) => fileIndex !== file.index);
    (filesFormArray as FormArray).clear();
    updatedFiles.forEach((file: Files) => {
      (filesFormArray as FormArray).push(this.fb.control(file));
    });
  }

  uploadFiles(): void {
    this.state.set({
      loading: true,
    });
    this.planItemFacade.uploadFiles(this.filesForm.value).pipe(
      finalize(() => {
        this.state.set({
          loading: false,
        });
      }),
    ).subscribe(() => {
      this.filesForm.clear();
      this.notification.next({
        message: 'Files uploaded successfully.',
        type: NotificationType.Success,
      });
    });
  }

  private _openRetailerPlanCancellationModal(plan: Plan): void {
    const dialog = this.promptDialogService.openPrompt({
      message: `<span>Are you sure you want to cancel this plan <b>${plan.consumerPlanName}</b>?</span>`,
      buttonNo: 'No, don\'t cancel',
      buttonYes: 'Yes, cancel plan',
    });
    dialog.afterClosed().pipe(
      switchMap(result => {
        if (result) {
          this.planService.cancellationInProgress$.next(true);
          return this.retailerPlanApiService
            .returnPlan(plan.id)
            .pipe(
              tap(refund => {
                if (refund.errorMessage) {
                  this.planService.notifyCancellationError(refund);
                } else {
                  this.planItemFacade.setNewProtectionPlanStatus(refund.protectionPlanStatus || null);
                  this.plansStore.setNewProtectionPlanStatus(refund.protectionPlanStatus || null, plan.id);
                  this.notification.next({
                    message: `Plan ${plan.consumerPlanName} has been cancelled.`,
                    type: NotificationType.Success,
                    duration: 6000,
                  });
                }
              }),
              finalize(() => {
                this.planService.cancellationInProgress$.next(false);
              }),
            );
        } else {
          return of(true);
        }
      }),
    ).subscribe();
  }
}
