<ng-container *ngIf="data[fieldName]">{{handledText}}</ng-container>
<div class="retailer-action__button" *ngFor="let retailerAction of this.data.actions">
  <button class="_common-button--rounded" *ngIf="(retailerAction.features | appAllowed) === true"
          [class._common-button__two-lines]="retailerAction.titleSecondLine"
          mat-flat-button
          color="primary"
          (click)="handleAction(retailerAction.action)"
          [disabled]="(retailerAction.features | appAllowed) === false">
    {{retailerAction.title}}
    <ng-container *ngIf="retailerAction.titleSecondLine">
      <br>{{retailerAction.titleSecondLine}}
    </ng-container>
  </button>
</div>
