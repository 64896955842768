<div class="shipping-label" *rxLet="vm$; let vm">
  <div class="shipping-label__actions" *ngIf="vm.showShippingLabel">
    <div [matTooltip]="data.disableShippingLabelReason">
      <a [class.shipping-label_disabled]="!!data.disableShippingLabelReason || data.shippingLabelLoading"
         [href]="data.shippingLabelUrl" target="_blank">
        <button
          class="_common-button--rounded"
          [disabled]="!!data.disableShippingLabelReason || data.shippingLabelLoading"
          appLoadingButton
          [isLoading]="data.shippingLabelLoading"
          mat-flat-button>Shipping Label</button>
      </a>
    </div>
    <div [matTooltip]="data.disableShippingLabelReason" *ngIf="vm.showPackageInsert">
      <button
        appLoadingButton
        [isLoading]="vm.printPackageLoading"
        [disabled]="!!data.disableShippingLabelReason || vm.printPackageLoading"
        mat-flat-button
        (click)="printPackage()"
        class="_common-button--rounded">
        Package Insert
      </button>
    </div>
  </div>
</div>
